<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header :title="$t('collection_screen')" :isFilter="false">
                </Header>
            </template>
            <template v-slot:header-mobile>
                <HeaderMobile :title="$t('collection_screen')" :isFilter="false">
                </HeaderMobile>
            </template>
            <div class="row align-items-center">
                <div class="col-12">
                    <b-button @click="save"
                              type="button"
                              variant="primary"
                              class="btn-lg btn-block col-lg-2 col-xl-1 col-md-2 mb-4" :disabled="saving"
                    ><b-spinner label="Spinning" class="align-middle mr-2" small
                                v-show="saving"></b-spinner>
                        {{ $t('save') | toUpperCase }}
                    </b-button>
                    <div class="row" v-if="paymentPlan!=null && paymentPlan.payment_plan.customer!=null">
                        <div class="col-12 col-md-6 col-lg-6 col-xl-4 mb-3 ellipsis-1">
                            <label class="col-form-label mr-1 p-0">{{ $t('name_surname') }}:</label>
                            {{ paymentPlan.payment_plan.customer.name }} {{ paymentPlan.payment_plan.customer.surname }}
                        </div>
                        <div class="col-12 col-md-6 col-lg-6 col-xl-4 mb-3 ellipsis-1">
                            <label class="col-form-label mr-1 p-0">{{ $t('student_number') }}:</label>
                            {{ paymentPlan.payment_plan.customer.number }}
                        </div>
                        <div class="col-12 col-md-6 col-lg-6 col-xl-4 mb-3 ellipsis-1">
                            <label class="col-form-label mr-1 p-0">{{ $t('tc_no') }}:</label>
                            {{ paymentPlan.payment_plan.customer.national_id }}
                        </div>
                        <div class="col-12 col-md-6 col-lg-6 col-xl-4 mb-3 ellipsis-1">
                            <label class="col-form-label mr-1 p-0">{{ $t('faculty') }}:</label>
                            {{ paymentPlan.student.faculty_name }}
                        </div>
                        <div class="col-12 col-md-6 col-lg-6 col-xl-4 mb-3 ellipsis-1">
                            <label class="col-form-label mr-1 p-0">{{ $t('program') }}:</label>
                            {{ paymentPlan.student.program_name }}
                        </div>
                        <div class="col-12 col-md-6 col-lg-6 col-xl-4 mb-3" v-if="!isPayment">
                            <b-form-radio v-model="paymentPlan.payment_plan.is_cash" :value="true" v-on:change="isCashUpdated()">
                                <span>{{$t('cash')}} (%{{parseFloat(paymentPlan.payment_plan.cash_discount_rate)}}) {{ parseFloat(paymentPlan.payment_plan.cash_price) | formatToCurrency(paymentPlan.payment_plan.currency)}}</span>
                            </b-form-radio>
                            <b-form-radio v-model="paymentPlan.payment_plan.is_cash" :value="false" v-on:change="isCashUpdated()">
                                <span>{{$t('with_installment')}} {{ parseFloat(paymentPlan.payment_plan.original_price) | formatToCurrency(paymentPlan.payment_plan.currency)}}</span>
                            </b-form-radio>
                        </div>
                        <div class="col-12 col-md-6 col-lg-6 col-xl-4 mb-3 ellipsis-1" v-if="!isPayment">
                            <label class="col-form-label mr-1 p-0">{{ $t('paid') }}:</label>
                            {{ parseFloat(paymentPlan.payment_plan.payments_sum_amount) - parseFloat(paymentPlan.payment_plan.refunds_sum_amount) | formatToCurrency(paymentPlan.payment_plan.currency)}}
                        </div>
                        <div class="col-12 col-md-6 col-lg-6 col-xl-4 mb-3 ellipsis-1" v-if="isPayment">
                            <label class="col-form-label mr-1 p-0">{{ $t('paid') }}:</label>
                            {{ parseFloat(this.newPayment) | formatToCurrency(paymentPlan.payment_plan.currency)}}
                        </div>
                        <div :class="'col-12 col-md-6 col-lg-6 col-xl-4 mb-3 ellipsis-1'+((this.balance - this.newPayment)<0?' text-danger':'')">
                            <label :class="'col-form-label mr-1 p-0'+((this.balance - this.newPayment)<0?' text-danger':'')">{{ $t('balance') }}:</label>
                            {{ this.balance - this.newPayment | formatToCurrency(paymentPlan.payment_plan.currency)}}
                        </div>
                        <div class="col-12 col-md-6 col-lg-6 col-xl-4 mb-3 ellipsis-1" v-if="isPayment">
                            <label class="col-form-label mr-1 p-0">{{ $t('amount') }}:</label>
                            {{ paymentPlan.payment_plan.payment.amount | formatToCurrency(paymentPlan.payment_plan.currency)}}
                        </div>
                        <div class="col-12 col-md-6 col-lg-6 col-xl-4 mb-3">
                            <label class="col-form-label mr-1 p-0">{{ $t('currency_rate') }}:</label>
                            <b-form-input class="col-4"
                                          type="number" step=".0001"
                                          v-model="paymentPlan.payment_plan.currency_rate"></b-form-input>
                        </div>
                        <div class="col-12">
                            <label class="col-form-label mr-1 p-0">{{ $t('explanation') }}:</label>
                            <b-form-input class="col-12"
                                          v-model="explanation"></b-form-input>
                        </div>
                    </div>
                    <b-tabs content-class="py-5 position-relative" class="mt-5" v-model="tabIndex">
                        <div class="tabs-bottom-line"></div>
                        <b-tab active>
                            <template #title>{{ $t('credit_card') }}</template>
                            <CreditCard ref="creditCard" v-if="paymentTypes!=null && paymentPlan!=null"
                                        v-on:creditCardAdded="creditCardAdded"
                                        :paymentTypes="paymentTypes"
                                        :isPayment="isPayment"
                                        :paymentPlan="paymentPlan">

                            </CreditCard>
                        </b-tab>
                        <b-tab v-if="!isPayment">
                            <template #title>{{ $t('check') }}</template>
                            <Check ref="check" v-if="paymentPlan!=null"
                                   v-on:checkAdded="checkAdded"
                                   :paymentPlan="paymentPlan">

                            </Check>
                        </b-tab>
                        <b-tab v-if="!isPayment">
                            <template #title>{{ $t('promissory_note') }}</template>
                            <Promissory ref="promissory" v-if="paymentPlan!=null && paymentTypes!=null"
                                        v-on:promissoryAdded="promissoryAdded"
                                        :paymentTypes="paymentTypes"
                                        :paymentPlan="paymentPlan">
                            </Promissory>
                        </b-tab>
                        <b-tab v-if="!isPayment">
                            <template #title>{{ $t('mail_order') }}</template>
                            <MailOrder ref="mailOrder" v-if="paymentPlan!=null"
                                        v-on:mailOrderAdded="mailOrderAdded"
                                        :paymentPlan="paymentPlan">
                            </MailOrder>
                        </b-tab>
                        <b-tab v-if="!isPayment">
                            <template #title>{{ $t('future_payment') }}</template>
                            <Cash ref="cash" v-if="paymentTypes!=null && paymentPlan!=null"
                                  v-on:cashAdded="cashAdded"
                                  :paymentTypes="paymentTypes"
                                  :paymentPlan="paymentPlan">

                            </Cash>
                        </b-tab>
                    </b-tabs>
                </div>
            </div>
        </app-layout>
    </div>
</template>
<script>
import AppLayout from "@/layouts/AppLayout";
import PaymentTypeService from "@/services/PaymentTypeService";
import CustomerService from "@/services/CustomerService";
import YearService from "@/services/YearService";
import CreditCard from "@/modules/payments/pages/tabs/CreditCard";
import Cash from "@/modules/payments/pages/tabs/Cash";
import Check from "@/modules/payments/pages/tabs/Check";
import Promissory from "@/modules/payments/pages/tabs/Promissory";
import MailOrder from "@/modules/payments/pages/tabs/MailOrder";
import PaymentPlanService from "@/services/PaymentPlanService";
import Header from "@/layouts/AppLayout/Header"
import HeaderMobile from "@/layouts/AppLayout/HeaderMobile"

export default {
    components: {
        CreditCard,
        Cash,
        Check,
        Promissory,
        AppLayout,
        MailOrder,
        HeaderMobile,
        Header
    },
    data() {
        return {
            paymentPlanId: this.$route.params.payment_plan_id,
            paymentId: this.$route.params.payment_id,
            tabIndex: 1,
            paymentTypes: null,
            paymentPlan: null,
            newPayment: 0,
            receiptNumber: null,
            creditCardTotal: 0,
            cashTotal: 0,
            checkTotal: 0,
            promissoryTotal: 0,
            mailOrderTotal: 0,
            balance:null,
            price:null,
            explanation:null,
            saving:false,
            isPayment:false,
        }
    },

    metaInfo() {
        return {
            title: this.$t("collection_screen")
        }
    },
    created() {
        this.init();
    },
    methods: {
        async init() {
            if(this.paymentId){
                this.isPayment=true;
            } else {
                this.paymentId=0;
            }
            this.loadData();
            PaymentTypeService.getAllOptions()
                .then(response => {
                    this.paymentTypes = response.data.data;
                });

        },
        loadData(){
            CustomerService.getPaymentPlan(this.paymentPlanId, this.paymentId)
                .then(response => {
                    this.paymentPlan = response.data.data;
                    this.price=this.paymentPlan.payment_plan.original_price;
                    if(this.isPayment){
                        this.balance=this.paymentPlan.payment_plan.payment.balance;
                    } else {
                        this.balance=this.paymentPlan.payment_plan.price-this.paymentPlan.payment_plan.payments_sum_amount+this.paymentPlan.payment_plan.refunds_sum_amount;
                    }
                });
        },
        isCashUpdated(){
            if(this.paymentPlan.payment_plan.is_cash){
                this.paymentPlan.payment_plan.price=this.paymentPlan.payment_plan.cash_price;
            }else{
                this.paymentPlan.payment_plan.price=this.paymentPlan.payment_plan.original_price;
            }

            this.balance=this.paymentPlan.payment_plan.price-this.paymentPlan.payment_plan.payments_sum_amount+this.paymentPlan.payment_plan.refunds_sum_amount;;

        },
        save() {
            let total = this.creditCardTotal + this.cashTotal + this.checkTotal + this.promissoryTotal + this.mailOrderTotal;
            if (total === 0) {
                return;
            }

            this.saving=true;
            if (this.receiptNumber == null && total - this.cashTotal>0) {
                YearService.getReceiptNumber(this.paymentPlan.payment_plan.prices.period.year_code)
                    .then(response => {
                        this.receiptNumber = response.data.data;
                        if(!this.receiptNumber){
                            this.$toast.error(this.$t('receipt_number_not_created'));
                            return;
                        }
                        if(this.paymentPlan.payment_plan.is_cash &&
                            this.paymentPlan.payment_plan.cash_price<this.paymentPlan.payment_plan.original_price){
                            this.saveCash();
                        }
                        this.saveChilds();
                    });
            } else {
                this.saveChilds();
            }
        },
        saveCash(){
            if(this.checkPermission('paymentplan_cash')){
                PaymentPlanService.storeCash(this.paymentPlan.payment_plan.id, this.paymentPlan.payment_plan.is_cash?1:0);
            } else {
                this.$toast.error(this.$t('you_are_not_authorized_for_this_operation'))
            }

        },
        async saveChilds() {
            let creditCard = await this.$refs.creditCard.save(this.receiptNumber, this.explanation);
            let cash=true;
            let check=true;
            let promissory=true;
            let mailOrder=true;
            if(!this.isPayment){
                cash = await this.$refs.cash.save(this.receiptNumber, this.explanation);
                check = await this.$refs.check.save(this.receiptNumber, this.explanation);
                promissory = await this.$refs.promissory.save(this.receiptNumber, this.explanation);
                mailOrder = await this.$refs.mailOrder.save(this.receiptNumber, this.explanation);
            }

            if(creditCard && cash && check && promissory && mailOrder){
                this.$toast.success(this.$t('payments_saved'));
                let postfix = '';
                if(this.receiptNumber!=null){
                    postfix = '?receipt_number='+this.receiptNumber;
                }
                if(this.paymentPlan.payment_plan.student_program_id){
                    this.$router.push('/customers/payments/student/'+this.paymentPlan.payment_plan.student_program_id + postfix);
                } else if(this.paymentPlan.payment_plan.registration_id){
                    this.$router.push('/customers/payments/registration/'+this.paymentPlan.payment_plan.registration_id + postfix);
                }
            }
            this.saving=false;
        },
        updateBalance(){
            this.newPayment = this.creditCardTotal + this.cashTotal + this.checkTotal + this.promissoryTotal + this.mailOrderTotal;

            if(this.newPayment>this.balance){
                this.$toast.error(this.$t('balance_overflow'));
            }
        },
        creditCardAdded(total) {
            this.creditCardTotal = total;
            this.updateBalance();
        },
        cashAdded(total) {
            this.cashTotal = total;
            this.updateBalance();
        },
        checkAdded(total) {
            this.checkTotal = total;
            this.updateBalance();
        },
        promissoryAdded(total) {
            this.promissoryTotal = total;
            this.updateBalance();
        },
        mailOrderAdded(total) {
            this.mailOrderTotal = total;
            this.updateBalance();
        }
    },
};
</script>
