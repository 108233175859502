<template>
        <ValidationObserver ref="form">
            <div class="row">
                <div class="col-lg-2 col-md-4 col-sm-6">
                    <ValidationProvider name="number_of_installments" rules="required" v-slot="{valid, errors}">
                        <b-form-group :label="$t('number_of_installments')">
                            <b-form-input
                                type="number"
                                min="1" max="12"
                                v-model="form.number_of_installments"
                            ></b-form-input>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                        </b-form-group>
                    </ValidationProvider>
                </div>
                <div class="col-lg-2 col-md-4 col-sm-6">
                    <ValidationProvider name="due_date" rules="required" v-slot="{valid, errors}">
                    <b-form-group :label="$t('due_date')">
                        <b-form-input
                            type="date"
                            v-model="form.due_date"
                        ></b-form-input>
                        <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                    </b-form-group>
                    </ValidationProvider>
                </div>
                <div class="col-lg-2 col-md-4 col-sm-6">
                    <ValidationProvider name="day_interval" rules="required" v-slot="{valid, errors}">
                        <b-form-group :label="$t('day_interval')">
                            <b-form-input
                                type="number"
                                v-model="form.day_interval"
                            ></b-form-input>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                        </b-form-group>
                    </ValidationProvider>
                </div>

                <div class="col-lg-2 col-md-4 col-sm-6">
                    <ValidationProvider name="amount" rules="required" v-slot="{valid, errors}">
                        <b-form-group :label="$t('installment_amount')">
                            <b-form-input
                                type="number"
                                min="0" step=".01"
                                v-check-min-max-value
                                v-model="form.amount"
                            ></b-form-input>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                        </b-form-group>
                    </ValidationProvider>
                </div>

                <div class="col-lg-2 col-md-4 col-sm-6">
                    <ValidationProvider name="check_no" rules="required" v-slot="{valid, errors}">
                        <b-form-group :label="$t('check_no')">
                            <b-form-input
                                v-model="form.check_no"
                            ></b-form-input>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                        </b-form-group>
                    </ValidationProvider>
                </div>

                <div class="col-lg-2 col-md-4 col-sm-6">
                    <ValidationProvider name="bank" rules="required" v-slot="{valid, errors}">
                        <b-form-group :label="$t('bank')">
                            <bank-selectbox :validate-error="errors[0]"
                                            ref="bank"
                                                      v-model="form.bank">
                            </bank-selectbox>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                        </b-form-group>
                    </ValidationProvider>
                </div>

                <div class="col-lg-2 col-md-4 col-sm-6">
                    <ValidationProvider name="account_number" rules="required" v-slot="{valid, errors}">
                        <b-form-group :label="$t('account_number')">
                            <b-form-input
                                v-model="form.account_number"
                            ></b-form-input>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                        </b-form-group>
                    </ValidationProvider>
                </div>

                <div class="col-lg-2 col-md-4 col-sm-6">
                    <ValidationProvider name="drawer" rules="required" v-slot="{valid, errors}">
                        <b-form-group :label="$t('check_drawer')">
                            <b-form-input
                                v-model="form.drawer"
                            ></b-form-input>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                        </b-form-group>
                    </ValidationProvider>
                </div>

                <div class="col-lg-1 col-md-4 col-sm-6">
                    <div class="container d-flex h-100">
                        <div class="row justify-content-center align-self-center mt-1">
                            <b-button @click="submitForm"
                                      type="button"
                                      variant="outline-primary"
                            >
                                <i class="ri-add-line mr-2"></i>{{ $t('add') }}
                            </b-button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row" v-if="rows.length>0">
                <div class="col-12">
                    <table class="table table-bordered table-striped">
                        <thead>
                        <tr>
                            <th>{{$t('due_date') }}}</th>
                            <th>{{$t('amount') }}}</th>
                            <th>{{$t('check_no') }}}</th>
                            <th>{{$t('bank') }}}</th>
                            <th>{{$t('account_number') }}}</th>
                            <th>{{$t('check_drawer') }}}</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(row,i) in rows" :key="i">
                            <td>
                                <b-form-input
                                type="date"
                                v-model="row.due_date">
                                </b-form-input>
                            </td>
                            <td><b-form-input
                                type="number"
                                @update="amountUpdated"
                                v-model="row.amount">
                            </b-form-input></td>
                            <td>{{row.related_number}}</td>
                            <td>{{row.bank}}</td>
                            <td>{{row.account_number}}</td>
                            <td>{{row.related_info}}</td>
                            <td class="w-40">
                                <b-button @click="deleteRow(row.id)"
                                          type="button"
                                          variant="danger"
                                >
                                    {{ $t('delete') }}
                                </b-button>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>

        </ValidationObserver>
</template>
<script>
import {ValidationObserver, ValidationProvider} from "vee-validate";
import PaymentService from "@/services/PaymentService";
import BankSelectbox from "@/components/interactive-fields/BankSelectbox";

export default {
    components: {
        ValidationProvider,
        ValidationObserver,
        BankSelectbox
    },
    props: {
        paymentTypes: {
            type: Object,
            default: null
        },
        paymentPlan: {
            type: Object,
            default: null
        },
    },
    data() {
        return {
            form: {
                day_interval:30,
                number_of_installments:1
            },
            typeOptions: null,
            types: null,
            rows:[],
            total:0,
            index:0
        }
    },
    created() {
        this.init();
    },
    methods: {
        init() {
        },
        async submitForm(){
            if(this.checkPermission('payment_checkpayment')){
                const isValid = await this.$refs.form.validate();
                if(isValid){
                    let formData={
                        payment_plan_id:this.paymentPlan.payment_plan.id,
                        amount: parseFloat(this.form.amount),
                        currency: this.paymentPlan.payment_plan.currency,
                        number_of_installments: this.form.number_of_installments,
                        due_date: this.form.due_date,
                        day_interval: this.form.day_interval,
                        related_number: this.form.check_no,
                        bank_id: this.form.bank,
                        account_number: this.form.account_number,
                        related_info: this.form.drawer,
                        currency_rate: this.paymentPlan.payment_plan.currency_rate,
                    };

                    PaymentService.storeCheck(formData, true)
                                  .then(response => {
                                      response.data.data.forEach(item=>{
                                          item.id=this.index++;
                                          item.bank=this.$refs.bank.getText(parseInt(item.bank_id));
                                          this.rows.push(item);
                                      });
                                      this.total += parseFloat(formData.amount) * parseInt(formData.number_of_installments);
                                      this.$emit('checkAdded', this.total);
                                      this.form.amount=null;
                                      this.form.due_date=null;
                                      this.form.ralated_number=null;
                                      this.form.account_number=null;
                                      this.form.bank=null;
                                      this.form.drawer=null;
                                      this.$refs.form.reset();
                                  })
                                  .catch(e => {
                                      this.$toast.error(this.$t('api.' + e.data.message));
                                      if(e.status===422 && e.data.errors){
                                          Object.keys(e.data.errors).forEach(key=>{
                                              this.$refs.form.errors[key].push(e.data.errors[key][0]);
                                          });
                                      }
                                  });
                }
            } else {
                this.$toast.error(this.$t('you_are_not_authorized_for_this_operation'))
            }

        },
        deleteRow(id) {
            this.rows = this.rows.filter(function(item){
                return item.id!=id;
            });

            this.amountUpdated();
        },
        amountUpdated(){
            this.total = 0;
            this.rows.forEach(item=>{
                this.total += parseFloat(item.amount);
            })
            this.$emit('checkAdded', this.total);
        },
        save(receiptNumber, explanation){
            let failed=[];
            this.rows.forEach(row=>{
                row.receipt_number=receiptNumber;
                row.explanation=explanation;
                row.number_of_installments=1;
                row.day_interval=0;
                PaymentService.storeCheck(row, false)
                    .then(response => {

                    })
                    .catch(e => {
                        this.$toast.error(this.$t('api.' + e.data.message));
                        failed.push(row);
                    });
            });

            this.rows=failed;

            return failed.length===0;
        }

    },
}
</script>
