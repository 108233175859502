<template>
    <ValidationObserver ref="form">
        <div class="row">
            <div class="col-lg-2 col-md-4 col-sm-6">
                <ValidationProvider name="number_of_installments" rules="required" v-slot="{valid, errors}">
                    <b-form-group :label="$t('number_of_installments')">
                        <b-form-input
                            type="number"
                            min="1" max="12"
                            v-model="form.number_of_installments"
                        ></b-form-input>
                        <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                    </b-form-group>
                </ValidationProvider>
            </div>
            <div class="col-lg-2 col-md-4 col-sm-6">
                <ValidationProvider name="due_date" rules="required" v-slot="{valid, errors}">
                    <b-form-group :label="$t('due_date')">
                        <b-form-input
                            type="date"
                            v-model="form.due_date"
                        ></b-form-input>
                        <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                    </b-form-group>
                </ValidationProvider>
            </div>
            <div class="col-lg-2 col-md-4 col-sm-6">
                <ValidationProvider name="day_interval" rules="required" v-slot="{valid, errors}">
                    <b-form-group :label="$t('day_interval')">
                        <b-form-input
                            type="number"
                            v-model="form.day_interval"
                        ></b-form-input>
                        <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                    </b-form-group>
                </ValidationProvider>
            </div>

            <div class="col-lg-2 col-md-4 col-sm-6">
                <ValidationProvider name="amount" rules="required" v-slot="{valid, errors}">
                    <b-form-group :label="$t('installment_amount')">
                        <b-form-input
                            type="number"
                            min="0" step=".01"
                            v-check-min-max-value
                            v-model="form.amount"
                        ></b-form-input>
                        <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                    </b-form-group>
                </ValidationProvider>
            </div>

            <div class="col-12">
                <h6 class="mb-3">{{ $t('credit_card_info') }}</h6>
            </div>

            <div class="col-lg-2 col-md-4 col-sm-6">
                <ValidationProvider name="credit_card_number" rules="required" v-slot="{valid, errors}">
                    <b-form-group :label="$t('credit_card_number')">
                        <b-form-input
                            v-model="form.credit_card_number"
                            v-mask="'####-####-####-####'"
                            autocomplete="false"
                        ></b-form-input>
                        <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                    </b-form-group>
                </ValidationProvider>
            </div>
            <div class="col-lg-2 col-md-4 col-sm-6">
                <ValidationProvider name="card_holder_name" rules="required" v-slot="{valid, errors}">
                    <b-form-group :label="$t('card_holder_name')">
                        <b-form-input
                            v-model="form.card_holder_name"
                        ></b-form-input>
                        <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                    </b-form-group>
                </ValidationProvider>
            </div>

            <div class="col-lg-2 col-md-4 col-sm-6">
                <ValidationProvider name="card_holder_address" rules="required" v-slot="{valid, errors}">
                    <b-form-group :label="$t('card_holder_address')">
                        <b-form-input
                            v-model="form.card_holder_address"
                        ></b-form-input>
                        <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                    </b-form-group>
                </ValidationProvider>
            </div>

            <div class="col-lg-2 col-md-4 col-sm-6">
                <ValidationProvider name="card_holder_phone" rules="required" v-slot="{valid, errors}">
                    <b-form-group :label="$t('card_holder_phone')">
                        <b-form-input
                            v-model="form.card_holder_phone"
                            v-mask="'(###)-###-####'"
                            placeholder="(###)-###-####"
                        ></b-form-input>
                        <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                    </b-form-group>
                </ValidationProvider>
            </div>

            <div class="col-lg-1 col-md-2 col-sm-6">
                <ValidationProvider name="expiry_month" rules="required" v-slot="{valid, errors}">
                    <b-form-group :label="$t('expiry_month')">
                        <b-form-select
                            v-model="form.expiry_month"
                        >
                            <option>{{$t('select')}}</option>
                            <option value="01">01</option>
                            <option value="02">02</option>
                            <option value="03">03</option>
                            <option value="04">04</option>
                            <option value="05">05</option>
                            <option value="06">06</option>
                            <option value="07">07</option>
                            <option value="08">08</option>
                            <option value="09">09</option>
                            <option value="10">10</option>
                            <option value="11">11</option>
                            <option value="12">12</option>
                        </b-form-select>
                        <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                    </b-form-group>
                </ValidationProvider>
            </div>
            <div class="col-lg-1 col-md-2 col-sm-6">
                <ValidationProvider name="expiry_year" rules="required" v-slot="{valid, errors}">
                    <b-form-group :label="$t('expiry_year')">
                        <b-form-select
                            min=""
                            v-model="form.expiry_year"
                        >
                            <option>{{$t('select')}}</option>
                            <option v-for="year in years" :key="year" :value="year">{{year}}</option>
                        </b-form-select>
                        <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                    </b-form-group>
                </ValidationProvider>
            </div>

            <div class="col-lg-1 col-md-2 col-sm-6">
                <ValidationProvider name="mail_order_bank_id" rules="required" v-slot="{valid, errors}">
                    <b-form-group :label="$t('bank')">
                        <bank-selectbox :validate-error="errors[0]"
                                        ref="bank"
                                        v-model="form.mail_order_bank_id">
                        </bank-selectbox>
                        <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                    </b-form-group>
                </ValidationProvider>
            </div>

            <div class="col-lg-1 col-md-4 col-sm-6">
                <div class="container d-flex h-100">
                    <div class="row justify-content-center align-self-center mt-1">
                        <b-button @click="submitForm"
                                  type="button"
                                  variant="outline-primary"
                        >
                            <i class="ri-add-line mr-2"></i>{{ $t('add') }}
                        </b-button>
                    </div>
                </div>
            </div>
        </div>

        <div class="row" v-if="rows.length>0">
            <div class="col-12">
                <table class="table table-bordered table-striped">
                    <thead>
                    <tr>
                        <th>{{ $t('due_date')}}</th>
                        <th>{{ $t('amount') }}</th>
                        <th>{{ $t('credit_card_number')}}</th>
                        <th>{{ $t('card_holder_name')}}</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(row,i) in rows" :key="i">
                        <td>
                            <b-form-input
                                type="date"
                                v-model="row.due_date">
                            </b-form-input>
                        </td>
                        <td>
                            <b-form-input
                                type="number"
                                @update="amountUpdated"
                                v-model="row.amount">
                            </b-form-input>
                        </td>
                        <td>{{ row.credit_card_number }}</td>
                        <td>{{ row.card_holder_name }}</td>
                        <td class="w-40">
                            <b-button @click="deleteRow(row.id)"
                                      type="button"
                                      variant="danger"
                            >
                                {{ $t('delete') }}
                            </b-button>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>

    </ValidationObserver>
</template>
<script>
import {ValidationObserver, ValidationProvider} from "vee-validate";
import PaymentService from "@/services/PaymentService";
import BankSelectbox from "@/components/interactive-fields/BankSelectbox";

export default {
    components: {
        ValidationProvider,
        ValidationObserver,
        BankSelectbox,
    },
    props: {
        paymentTypes: {
            type: Object,
            default: null
        },
        paymentPlan: {
            type: Object,
            default: null
        },
    },
    data() {
        return {
            form: {
                day_interval: 30,
                number_of_installments: 1
            },
            typeOptions: null,
            types: null,
            rows: [],
            total: 0,
            years:null,
            index:0
        }
    },
    created() {
        this.init();
    },
    methods: {
        init() {
            this.years=[];
            let year=(new Date()).getFullYear()-2000;
            for(var i=0;i<10;i++)
            {
                this.years.push(year+i);
            }

        },
        async submitForm() {
            if(this.checkPermission('payment_mailorderpayment')){
                const isValid = await this.$refs.form.validate();
                if (isValid) {
                    let formData = {
                        payment_plan_id: this.paymentPlan.payment_plan.id,
                        amount: this.form.amount,
                        currency: this.paymentPlan.payment_plan.currency,
                        number_of_installments: this.form.number_of_installments,
                        due_date: this.form.due_date,
                        day_interval: this.form.day_interval,
                        credit_card_number: this.form.credit_card_number,
                        expiry_month: this.form.expiry_month,
                        expiry_year: this.form.expiry_year,
                        card_holder_name: this.form.card_holder_name,
                        card_holder_address: this.form.card_holder_address,
                        card_holder_phone: this.form.card_holder_phone,
                        mail_order_bank_id: this.form.mail_order_bank_id,
                        currency_rate: this.paymentPlan.payment_plan.currency_rate,
                    };

                    PaymentService.storeMailOrder(formData, true)
                                  .then(response => {
                                      response.data.data.forEach(item=>{
                                          item.credit_card_number=this.form.credit_card_number;
                                          item.expiry_month=this.form.expiry_month;
                                          item.expiry_year=this.form.expiry_year;
                                          item.card_holder_name=this.form.card_holder_name;
                                          item.card_holder_address=this.form.card_holder_address;
                                          item.card_holder_phone=this.form.card_holder_phone;
                                          item.mail_order_bank_id=this.form.mail_order_bank_id;
                                          item.id=this.index++;
                                          this.rows.push(item);
                                      });
                                      this.total += parseFloat(formData.amount) * parseInt(formData.number_of_installments);
                                      this.$emit('mailOrderAdded', this.total);
                                      this.form = {
                                          day_interval: 30,
                                          number_of_installments: 1
                                      }

                                      this.$refs.form.reset();
                                  })
                                  .catch(e => {
                                      this.$toast.error(this.$t('api.' + e.data.message));
                                      if(e.status===422 && e.data.errors){
                                          Object.keys(e.data.errors).forEach(key=>{
                                              this.$refs.form.errors[key].push(e.data.errors[key][0]);
                                          });
                                      }
                                  });
                }
            } else {
                this.$toast.error(this.$t('you_are_not_authorized_for_this_operation'))
            }
        },
        deleteRow(id) {
            this.rows = this.rows.filter(function(item){
                return item.id!=id;
            });

            this.amountUpdated();
        },
        amountUpdated(){
            this.total = 0;
            this.rows.forEach(item=>{
                this.total += parseFloat(item.amount);
            })
            this.$emit('mailOrderAdded', this.total);
        },
        async save(receiptNumber, explanation) {
            let failed = [];
            let related_id=0;
            for(let i=0;i<this.rows.length;i++)
            {
                let row=this.rows[i];
                row.receipt_number = receiptNumber;
                row.explanation=explanation;
                row.number_of_installments=1;
                row.day_interval=0;
                if(related_id>0){
                    row.related_id=related_id;
                }
                await PaymentService.storeMailOrder(row, false)
                    .then(response => {
                        related_id = response.data.data[0].related_id;
                    })
                    .catch(e => {
                        this.$toast.error(this.$t('api.' + e.data.message));
                        failed.push(row);
                    });
            }

            this.rows = failed;

            return failed.length === 0;
        }

    },
}
</script>
