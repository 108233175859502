<template>
    <ValidationObserver ref="creditCardForm">
        <div class="row" v-if="creditCardForm">
            <div class="col-lg-2 col-md-4 col-sm-6">
                <ValidationProvider name="card_no" rules="required" v-slot="{valid, errors}">
                    <b-form-group :label="$t('card_no')">
                        <b-form-input
                            autocomplete="false"
                            v-mask="'####-****-****-####'"
                            v-model="creditCardForm.card_no"
                        ></b-form-input>
                        <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                    </b-form-group>
                </ValidationProvider>
            </div>

            <div class="col-lg-1 col-md-4 col-sm-6">
                <ValidationProvider name="provision" rules="required" v-slot="{valid, errors}">
                    <b-form-group :label="$t('provision')">
                        <b-form-input
                            v-model="creditCardForm.provision"
                        ></b-form-input>
                        <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                    </b-form-group>
                </ValidationProvider>
            </div>

            <div class="col-lg-2 col-md-2 col-sm-6">
                <b-form-group :label="$t('bank')">
                    <bank-selectbox ref="bank"
                                    v-model="creditCardForm.bank"></bank-selectbox>
                </b-form-group>
            </div>
            <div class="col-lg-1 col-md-2 col-sm-6">
                <ValidationProvider name="terminal" rules="required" v-slot="{valid, errors}">
                    <b-form-group :label="$t('terminal')">
                        <b-form-select :options="terminalOptions"
                                       v-model="creditCardForm.account_number"></b-form-select>
                        <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                    </b-form-group>
                </ValidationProvider>
            </div>
            <div class="col-lg-2 col-md-4 col-sm-6">
                <ValidationProvider name="payment_type" rules="required" v-slot="{valid, errors}">
                    <b-form-group :label="$t('payment_type')">
                        <b-form-select
                            v-model="creditCardForm.payment_type"
                            :options="typeOptions"></b-form-select>
                        <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                    </b-form-group>
                </ValidationProvider>
            </div>
            <div class="col-lg-1 col-md-2 col-sm-6">
                <ValidationProvider name="installment" rules="required" v-slot="{valid, errors}">
                    <b-form-group :label="$t('installment')">
                        <b-form-input
                            type="number"
                            min="1" max="12"
                            v-model="creditCardForm.installment"
                        ></b-form-input>
                        <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                    </b-form-group>
                </ValidationProvider>
            </div>
            <div class="col-lg-1 col-md-2 col-sm-6">
                <ValidationProvider name="amount" rules="required" v-slot="{valid, errors}">
                    <b-form-group :label="$t('amount')">
                        <b-form-input
                            type="number"
                            @keyup="amountChanged"
                            min="0" step=".01"
                            v-check-min-max-value
                            v-model="creditCardForm.amount"
                        ></b-form-input>
                        <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                    </b-form-group>
                </ValidationProvider>
            </div>

            <div class="col-lg-1 col-md-2 col-sm-6">
                <ValidationProvider name="currency" rules="required" v-slot="{valid, errors}">
                    <b-form-group :label="$t('currency')">
                        <currency-selectbox
                            :validate-error="errors[0]"
                            @change="currencyChanged"
                                            v-model="creditCardForm.currency"
                        ></currency-selectbox>
                    </b-form-group>
                </ValidationProvider>
            </div>

            <div class="col-lg-1 col-md-2 col-sm-6" v-if="this.showAmountTl">
                <b-form-group :label="$t('amount_tl')">
                    <b-form-input
                        readonly
                        v-model="creditCardForm.amount_tl"
                    ></b-form-input>
                </b-form-group>
            </div>

            <div class="col-lg-1 col-md-4 col-sm-6">
                <div class="container d-flex h-100">
                    <div class="row justify-content-center align-self-center mt-1">
                        <b-button @click="submitCreditCardForm"
                                  type="button"
                                  variant="outline-primary"
                        >
                            <i class="ri-add-line mr-2"></i>{{ $t('add') }}
                        </b-button>
                    </div>
                </div>
            </div>
        </div>

        <div class="row" v-if="rows.length>0">
            <div class="col-12">
                <table class="table table-bordered table-striped">
                    <thead>
                    <tr>
                        <th>{{ $t('payment_type') }}</th>
                        <th>{{ $t('installment') }}</th>
                        <th>{{ $t('amount') }}</th>
                        <th>{{ $t('card_no') }}</th>
                        <th>{{ $t('provision') }}</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(row,i) in rows" :key="i">
                        <td>{{ findPaymentType(row.payment_type_id).name }}</td>
                        <td>{{ row.number_of_installments }}</td>
                        <td>{{ row.amount }}</td>
                        <td>{{ row.related_info }}</td>
                        <td>{{ row.related_number }}</td>
                        <td>
                            <b-button @click="deleteRow(row.id)"
                                      type="button"
                                      variant="danger"
                            >
                                {{ $t('delete') }}
                            </b-button>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>

    </ValidationObserver>
</template>
<script>
import {ValidationObserver, ValidationProvider} from "vee-validate";
import PaymentService from "@/services/PaymentService";
import BankSelectbox from "@/components/interactive-fields/BankSelectbox";
import CurrencySelectbox from "@/components/interactive-fields/CurrencySelectbox";

export default {
    components: {
        CurrencySelectbox,
        BankSelectbox,
        ValidationProvider,
        ValidationObserver,
    },
    props: {
        paymentTypes: {
            type: Object,
            default: null
        },
        paymentPlan: {
            type: Object,
            default: null
        },
        isPayment: {
            type: Boolean,
            default: null
        }
    },
    data() {
        return {
            creditCardForm: null,
            showAmountTl: false,
            bankOptions: null,
            typeOptions: null,
            terminalOptions: [],
            rows: [],
            total: 0,
            index: 0
        }
    },
    created() {
        this.init();
    },
    watch: {
        'creditCardForm.bank': function (selected) {
            this.typeOptions = [
                {value: null, text: this.$t('select')}
            ];
            this.terminalOptions = [];
            if (!selected) {
                return;
            }
            let item = this.$refs.bank.getItem(selected);
            this.paymentTypes.credit_card[item.text].forEach((value) => {
                this.typeOptions.push({value: value.id, text: value.name})
            })
            if (item.options.terminals) {
                this.terminalOptions = ['', ...item.options.terminals];
            }
        }
    },
    methods: {
        init() {
            this.bankOptions = [
                {value: null, text: this.$t('select')}
            ];
            Object.keys(this.paymentTypes.credit_card).forEach((value) => {
                this.bankOptions.push({value: value, text: value})
            });
            //this.creditCardForm.currency = this.paymentPlan.payment_plan.currency;
            //this.showAmountTl = this.creditCardForm.currency !== 'TRY';
            this.creditCardForm = {
                installment: 1,
                amount_tl: 0,
                currency:'TRY'
            };

        },
        findPaymentType(id) {
            var found = null;
            Object.keys(this.paymentTypes.credit_card).forEach((value) => {
                this.paymentTypes.credit_card[value].forEach((item) => {
                    if (item.id === id) {
                        found = item;
                    }
                });
            });

            return found;
        },
        deleteRow(id) {
            this.rows = this.rows.filter(function (item) {
                return item.id != id;
            });

            this.total = 0;
            this.rows.forEach(item => {
                this.total += item.amount;
            })
        },
        async submitCreditCardForm() {
            if(this.checkPermission('payment_creditcardpayment')){
                const isValid = await this.$refs.creditCardForm.validate();
                if (isValid) {
                    let formData = {
                        payment_plan_id: this.paymentPlan.payment_plan.id,
                        payment_type_id: this.creditCardForm.payment_type,
                        amount: parseFloat(this.creditCardForm.amount),
                        number_of_installments: this.creditCardForm.installment,
                        currency: this.creditCardForm.currency,
                        currency_rate: this.paymentPlan.payment_plan.currency_rate,
                        related_info: this.creditCardForm.card_no,
                        related_number: this.creditCardForm.provision,
                        account_number: this.creditCardForm.account_number,
                    };
                    let amount=formData.amount;
                    if(formData.currency!==this.paymentPlan.payment_plan.currency){
                        if(formData.currency==='TRY'){
                            amount = amount / this.paymentPlan.payment_plan.currency_rate;
                        }
                        else {
                            amount = amount * this.paymentPlan.payment_plan.currency_rate;
                        }
                    }
                    if (this.isPayment && amount + this.total > this.paymentPlan.payment_plan.payment.amount) {
                        this.$toast.error(this.$t('cannot_overflow_balance'));
                        return;
                    }
                    if (this.isPayment) {
                        formData.parent_id = this.paymentPlan.payment_plan.payment.id;
                    }
                    PaymentService.storeCreditCard(formData, true)
                                  .then(response => {
                                      formData.id = this.index++;
                                      this.rows.push(formData);
                                      this.total += amount;
                                      this.$emit('creditCardAdded', this.total);
                                      this.creditCardForm.payment_type = null;
                                      this.creditCardForm.amount = null;
                                      this.creditCardForm.card_no = null;
                                      this.creditCardForm.provision = null;
                                      this.creditCardForm.account_number = null;
                                      this.$refs.creditCardForm.reset();
                                  })
                                  .catch(e => {
                                      this.$toast.error(this.$t('api.' + e.data.message));
                                      if (e.status === 422 && e.data.errors) {
                                          Object.keys(e.data.errors).forEach(key => {
                                              this.$refs.creditCardForm.errors[key].push(e.data.errors[key][0]);
                                          });
                                      }
                                  });
                }
            } else {
                this.$toast.error(this.$t('you_are_not_authorized_for_this_operation'))
            }
        },
        save(receiptNumber, explanation) {
            let failed = [];
            this.rows.forEach(row => {
                row.receipt_number = receiptNumber;
                row.explanation=explanation;
                PaymentService.storeCreditCard(row, false)
                    .then(response => {

                    })
                    .catch(e => {
                        this.$toast.error(this.$t('api.' + e.data.message));
                        failed.push(row);
                    });
            });

            this.rows = failed;

            return failed.length === 0;
        },
        amountChanged() {
            this.creditCardForm.amount_tl = parseFloat(this.creditCardForm.amount) * parseFloat(this.paymentPlan.payment_plan.currency_rate);
        },
        currencyChanged() {
            //this.showAmountTl = this.creditCardForm.currency!=='TRY';
        },

    },
}
</script>
