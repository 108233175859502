<template>
    <ValidationObserver ref="form">
        <div class="row">
            <div class="col-lg-2 col-md-4 col-sm-6">
                <ValidationProvider name="payment_type" rules="required" v-slot="{valid, errors}">
                    <b-form-group :label="$t('payment_type')">
                        <b-form-select
                            v-model="form.payment_type"
                            :options="typeOptions"></b-form-select>
                        <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                    </b-form-group>
                </ValidationProvider>
            </div>
            <div class="col-lg-2 col-md-4 col-sm-6">
                <ValidationProvider name="amount" rules="required" v-slot="{valid, errors}">
                    <b-form-group :label="$t('amount')">
                        <b-form-input
                            type="number"
                            min="0" step=".01"
                            v-check-min-max-value
                            v-model="form.amount"
                        ></b-form-input>
                        <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                    </b-form-group>
                </ValidationProvider>
            </div>

            <div class="col-lg-1 col-md-2 col-sm-6">
                <ValidationProvider name="currency" rules="required" v-slot="{valid, errors}">
                    <b-form-group :label="$t('currency')">
                        <currency-selectbox
                            :validate-error="errors[0]"
                            v-model="form.currency"
                        ></currency-selectbox>
                    </b-form-group>
                </ValidationProvider>
            </div>

            <div class="col-lg-2 col-md-4 col-sm-6">
                <b-form-group :label="$t('due_date')">
                    <b-form-input
                        type="date"
                        v-model="form.due_date"
                    ></b-form-input>
                </b-form-group>
            </div>

            <div class="col-lg-1 col-md-4 col-sm-6">
                <div class="container d-flex h-100">
                    <div class="row justify-content-center align-self-center mt-1">
                        <b-button @click="submitForm"
                                  type="button"
                                  variant="outline-primary"
                        >
                            <i class="ri-add-line mr-2"></i>{{ $t('add') }}
                        </b-button>
                    </div>
                </div>
            </div>
        </div>

        <div class="row" v-if="rows.length>0">
            <div class="col-12">
                <table class="table table-bordered table-striped">
                    <thead>
                    <tr>
                        <th>{{ $t('payment_type') }}</th>
                        <th>{{ $t('amount') }}</th>
                        <th>{{ $t('currency') }}</th>
                        <th>{{ $t('due_date') }}</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(row,i) in rows" :key="i">
                        <td>{{ types[row.payment_type_id].name }}</td>
                        <td>{{ row.amount }}</td>
                        <td>{{ row.currency }}</td>
                        <td>
                            <b-form-input
                                type="date"
                                v-model="row.due_date">
                            </b-form-input>
                        </td>
                        <td class="w-40">
                            <b-button @click="deleteRow(row.id)"
                                      type="button"
                                      variant="danger"
                            >
                                {{ $t('delete') }}
                            </b-button>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>

    </ValidationObserver>
</template>
<script>
import {ValidationObserver, ValidationProvider} from "vee-validate";
import PaymentService from "@/services/PaymentService";
import CurrencySelectbox from "@/components/interactive-fields/CurrencySelectbox";

export default {
    components: {
        ValidationProvider,
        ValidationObserver,
        CurrencySelectbox
    },
    props: {
        paymentTypes: {
            type: Object,
            default: null
        },
        paymentPlan: {
            type: Object,
            default: null
        },
    },
    data() {
        return {
            form: {
            },
            typeOptions: null,
            types: null,
            rows: [],
            total: 0,
            index: 0
        }
    },
    created() {
        this.init();
    },
    methods: {
        init() {
            this.typeOptions = [
                {value: null, text: this.$t('select')}
            ];
            this.types = [];
            this.paymentTypes.delay.forEach((value) => {
                this.typeOptions.push({value: value.id, text: value.name})
                this.types[value.id] = value;
            });
            this.paymentTypes.other.forEach((value) => {
                if(value.code=='PLTJ'){
                    this.typeOptions.push({value: value.id, text: value.name})
                    this.types[value.id] = value;
                }
            });
            this.form.currency = this.paymentPlan.payment_plan.currency;
        },
        async submitForm() {
            if(this.checkPermission('payment_cashpayment')){
                const isValid = await this.$refs.form.validate();

                if (isValid) {
                    let formData = {
                        payment_plan_id: this.paymentPlan.payment_plan.id,
                        payment_type_id: this.form.payment_type,
                        amount: this.form.amount,
                        currency: this.form.currency,
                        is_paid: 0,
                        due_date: this.form.due_date,
                        currency_rate: this.paymentPlan.payment_plan.currency_rate,
                    };

                    let amount=formData.amount;
                    if(formData.currency!==this.paymentPlan.payment_plan.currency){
                        if(formData.currency==='TRY'){
                            amount = amount / this.paymentPlan.payment_plan.currency_rate;
                        }
                        else {
                            amount = amount * this.paymentPlan.payment_plan.currency_rate;
                        }
                    }

                    PaymentService.storeCash(formData, true)
                                  .then(response => {
                                      formData.id=this.index++;
                                      this.rows.push(formData);
                                      this.total += parseFloat(amount);
                                      this.$emit('cashAdded', this.total);
                                      this.form.payment_type = null;
                                      this.form.amount = null;
                                      this.form.is_paid = null;
                                      this.form.due_date = null;
                                      this.$refs.form.reset();
                                  })
                                  .catch(e => {
                                      this.$toast.error(this.$t('api.' + e.data.message));
                                      if(e.status===422 && e.data.errors){
                                          Object.keys(e.data.errors).forEach(key=>{
                                              this.$refs.form.errors[key].push(e.data.errors[key][0]);
                                          });
                                      }
                                  });
                }
            } else {
                this.$toast.error(this.$t('you_are_not_authorized_for_this_operation'))
            }
        },
        deleteRow(id) {
            this.rows = this.rows.filter(function(item){
                return item.id!=id;
            });

            this.total = 0;
            this.rows.forEach(item=>{
                this.total += item.amount;
            })
        },
        save(receiptNumber, explanation) {
            let failed = [];
            this.rows.forEach(row => {
                row.receipt_number = receiptNumber;
                row.explanation=explanation;
                PaymentService.storeCash(row, false)
                    .then(response => {

                    })
                    .catch(e => {
                        this.$toast.error(this.$t('api.' + e.data.message));
                        failed.push(row);
                    });
            });

            this.rows = failed;

            return failed.length === 0;
        }

    },
}
</script>
